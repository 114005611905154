import React from 'react';
import { Container, Stepper, Step, StepLabel, Button, Paper } from '@mui/material';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Donation from '../components/recyclingStepper/Recycle';
import UserFeedback from '../components/UserFeedback';
import axios from 'axios';
import { postDonation } from '../services/apiService';

export interface IMEIresponse {
    Description: string;
    IMEI: string;
    MCE: string;
    Manufacturer: string;
    PONumber?: string;
    SOFTBunde: [];
    SmartPhone: string;
    TPNB: string
}

export interface FormRecyclingValues {
    Store: string;
    ManufacturerDonation: string;
    FreeTextDonation: string;
    IMEIdonation: string;
    ModelDonation: string;
    ReasonDonation: string;
    MceCheckDonation: boolean;
    FactoryReset: boolean;
    NoneFactoryResetReason: string;
    Seal1: string;
    Seal2: string;
}

const steps = ['Tesco Mobile Recycling'];

const getValidationSchema = (values: FormRecyclingValues) => {
    return Yup.object().shape({
        Store: Yup.string().required('Store is a required field'),
        IMEIdonation: values.MceCheckDonation ? Yup.string().notRequired() : Yup.string().matches(/^\d+$/, "IMEI must be a number")
            .length(15, "IMEI must be exactly 15 digits"),
        //IMEIdonation: Yup.string().when('MceCheckDonation', ([MceCheckDonation], schema) => (MceCheckDonation) ? schema.notRequired() : schema.matches(/^\d+$/, "IMEI must be a number").length(15, "IMEI must be exactly 15 digits")),
        ModelDonation: Yup.string().optional(),
        MceCheckDonation: Yup.boolean(),
        ReasonDonation: Yup.string().when('MceCheckDonation', ([MceCheckDonation], schema) => (MceCheckDonation) ? schema.required("This is a required field") : schema.optional()),
        ManufacturerDonation: Yup.string().required('Manufacturer is required'),
        FreeTextDonation: Yup.string().optional(),
        FactoryReset: Yup.boolean(),
        NoneFactoryResetReason: Yup.string().when('FactoryReset', ([FactoryReset], schema) => (FactoryReset) ? schema.required("This is a required field") : schema.optional()),
        Seal1: Yup.string().required("Seal 1 is a required field"),
        Seal2: Yup.string().required("Seal 2 is a required field")
    })
}

const initialRecyclingValues: FormRecyclingValues = {
    Store: '0000',
    ManufacturerDonation: '',
    FreeTextDonation: '',
    IMEIdonation: '',
    ModelDonation: '',
    ReasonDonation: '',
    MceCheckDonation: false,
    FactoryReset: false,
    NoneFactoryResetReason: '',
    Seal1: '',
    Seal2: ''
};

const Recycling: React.FC<{ user: any }> = ({ user }) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const isLastStep = activeStep === steps.length - 1;
    const [feedback, setFeedback] = React.useState({ open: false, message: "", severity: "success" });

    const handleSubmit = async (
        values: FormRecyclingValues,
        actions: FormikHelpers<FormRecyclingValues>
    ) => {
        if (isLastStep) {
            await bookDonation(values, actions)
            // setActiveStep(0); don't return to home page
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    };

    const bookDonation = async (
        values: FormRecyclingValues,
        actions: FormikHelpers<FormRecyclingValues>
    ) => {
        const post = {
            IMEI: values.IMEIdonation,
            Manufacturer: values.ManufacturerDonation,
            Model: values.ModelDonation,
            Reason: values.ReasonDonation,
            Notes: values.FreeTextDonation,
            StoreID: values.Store,
            FactoryReset: values.FactoryReset,
            NoneFactoryResetReason: values.NoneFactoryResetReason,
            Seal1: values.Seal1,
            Seal2: values.Seal2
        };
        // Final submission logic
        console.log('Submitted values:', post);

        try {
            actions.setSubmitting(true)
            setFeedback({ open: true, message: "Successfully submitted", severity: "success" });
            await postDonation(post);
        } catch (err) {
            console.error('Error posting donation:', err);
            if (axios.isAxiosError(err)) {
                setFeedback({ open: true, message: err.response?.data, severity: "error" });
                console.error("Axios error:", err.response?.data || err.message);
            }
        } finally {
            actions.setSubmitting(false)
        }

        // Reset all fields to default empty values except the "store" field.
        actions.resetForm({
            values: {
                ...initialRecyclingValues,
                Store: values.Store,
            }
        });
    }

    return (
        <Container maxWidth="xl" disableGutters sx={{ marginTop: 4 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Container maxWidth="xl" sx={{ marginTop: 2, padding: 5, boxShadow: '2px 3px 8px 0 rgba(0, 0, 0, .2)', borderRadius: '4px', }} component={Paper}>
                <Formik
                    initialValues={initialRecyclingValues}
                    validate={(values) => {
                        try {
                            // Get the current schema based on values and run validation synchronously
                            getValidationSchema(values).validateSync(values, { abortEarly: false });
                            return {}; // no errors
                        } catch (err) {
                            console.log(err)
                            const errors: { [key: string]: string } = {};
                            if (err instanceof Yup.ValidationError) {
                            err.inner.forEach((error) => {
                                if (error.path) {
                                errors[error.path] = error.message;
                                console.log(error.message)
                                }
                            });
                            }
                            return errors;
                        }
                    }}
                    onSubmit={handleSubmit}
                    validateOnMount
                >
                    {(formik) => (
                        <Form>
                            {activeStep === 0 && <Donation formik={formik} />}
                            <div style={{ marginTop: 16, paddingBottom: 8 }}>
                                {activeStep > 0 && (
                                    <Button
                                        onClick={() => setActiveStep(activeStep - 1)}
                                        variant="contained"
                                        //color="secondary"
                                        sx={{ marginRight: 2 }}
                                    >
                                        Back
                                    </Button>
                                )}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={formik.isSubmitting || !formik.isValid}
                                    sx={{ float: 'right' }}
                                >
                                    {isLastStep ? 'Submit' : 'Next'}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
                <UserFeedback 
                    open={feedback.open} 
                    message={feedback.message} 
                    severity={feedback.severity as "success" | "error"} 
                    onClose={() => setFeedback({ ...feedback, open: false })} 
                />
            </Container>
        </Container>
    );
};

export default Recycling;
