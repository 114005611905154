import React from "react";
import { Snackbar, Alert } from "@mui/material";

interface UserFeedbackProps {
    open: boolean;
    message: string;
    severity?: "success" | "error" | "warning" | "info";
    onClose: () => void;
}

const UserFeedback: React.FC<UserFeedbackProps> = ({ open, message, severity = "success", onClose }) => {
    return (
        <Snackbar open={open} autoHideDuration={3000} onClose={onClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert onClose={onClose} severity={severity} variant="filled">
                {message}
            </Alert>
        </Snackbar>
    );
};

export default UserFeedback;
