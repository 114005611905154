import React, { useEffect } from 'react';
import {
    TextField,
    MenuItem,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Container,
} from '@mui/material';
import { getstores, getStoreCollectionDetails } from '../services/apiService';


//import Api from '../service/api';

interface Box {
    IMEI: string;
    Manufacturer: string;
    ToteNum: string
    ToteSecondary: string
    BookingDate: any
    Model: string
}

interface DropDowns {
    stores: string[];
}

interface Values {
    Boxes: Box[];
}

interface Stores {
    store: string;
    email: string;
}


const Collection: React.FC = () => {

    const [dropDowns, setDropDowns] = React.useState<DropDowns>({ stores: [] });
    const [values, setValues] = React.useState<Values>({ Boxes: [] });
    const [stores, setStores] = React.useState<Stores>({ store: '', email: '' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storesResponse = await getstores();
                setDropDowns((prev) => ({ ...prev, stores: storesResponse.data }));
            } catch (err) {
                console.error('Error fetching stores:', err);
            }
        };

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange =
        (name: keyof Stores) => async (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setStores((prevStores) => ({ ...prevStores, [name]: value }));
            await getBoxes(value);
        };

    const getBoxes = async (store: string) => {
        try {
            const storesCollectionsResponse = await getStoreCollectionDetails(store);
            setValues((prev) => ({ ...prev, Boxes: storesCollectionsResponse.data }));
        } catch (err) {
            setValues({ Boxes: []})
            console.error('Error fetching stores collections:', err);
        }
    };

    const fieldStyles = { minWidth: "20%" };

    return (
        <Container component={Paper} maxWidth="xl" sx={{ marginTop: 2, padding: 5,  boxShadow: '2px 3px 8px 0 rgba(0, 0, 0, .2)',  borderRadius: '4px', }}>
                <TextField
                    select
                    label="Select Store ID"
                    variant="outlined"
                    value={stores.store}
                    onChange={handleChange('store')}
                    name="store"
                    sx={fieldStyles}
                    slotProps={{
                        inputLabel: {
                            shrink: true, // Prevent the label from moving up
                        },
                    }}
                    helperText="Select Store ID"
                >
                    {dropDowns.stores.map((item) => (
                        <MenuItem key={item} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </TextField>

                <Table sx={{ minWidth: 650, marginTop: 5 }} aria-label="Booked table" stickyHeader size="small">
                    <TableHead>
                        <TableRow hover>
                            <TableCell>Tote Number</TableCell>
                            <TableCell>Tote Secondary Number</TableCell>
                            <TableCell>IMEI</TableCell>
                            <TableCell>Booking Date</TableCell>
                            <TableCell>Manufacturer</TableCell>
                            <TableCell>Model</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values.Boxes.map((row) => (
                            <TableRow key={row.IMEI}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell key={row.ToteNum}>{row.ToteNum}</TableCell>
                                <TableCell key={row.ToteSecondary}>{row.ToteSecondary}</TableCell>
                                <TableCell key={row.IMEI}>{row.IMEI}</TableCell>
                                <TableCell key={row.BookingDate}>{row.BookingDate}</TableCell>
                                <TableCell key={row.Manufacturer}>{row.Manufacturer}</TableCell>
                                <TableCell key={row.Model}>{row.Model}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
        </Container>
    );
};

export default Collection;
