import { fetchAuthSession } from '@aws-amplify/auth';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://tlxbackmobilereturnsref.co.uk';

const getAuthHeaders = async () => {
  try {
    const session = await fetchAuthSession() ;
    return {
      Authorization: `Bearer ${session.tokens?.idToken?.toString()}`,
      'Content-Type': 'application/json',
    };
  } catch (error) {
    console.error('Error getting auth token:', error);
    return { 'Content-Type': 'application/json' }; // Fallback
  }
};

export const getstores = async () => {
  const headers = await getAuthHeaders();
  return axios.get(`${API_BASE_URL}/api/store`, { headers });
};

export const getIMEI = async (imei: string) => {
  const headers = await getAuthHeaders();
  return axios.get(`${API_BASE_URL}/api/lookup/tac?IMEI=${imei}&EAN=`, { headers });
};

export const unitLocked = async (imei: string) => {
  const headers = await getAuthHeaders();
  return axios.get(`${API_BASE_URL}/api/Lock?imei=${imei}`, { headers });
};

export const getStoreCollectionDetails = async (store: string) => {
  const headers = await getAuthHeaders();
  return axios.get(`${API_BASE_URL}/api/store/collectioninfo?storeID=${store}`, { headers });
};

export const getStoreBookedDetails = async (store: string) => {
  const headers = await getAuthHeaders();
  return axios.get(`${API_BASE_URL}/api/Unit?storeID=${store}`, { headers });
};

export const getDonationsManufacturer = async () => {
  const headers = await getAuthHeaders();
  return axios.get(`${API_BASE_URL}/api/referencedata/manufacturer`, { headers });
};

export const getModelsAcc = async () => {
  const headers = await getAuthHeaders();
  return axios.get(`${API_BASE_URL}/api/AccessoryLookup/accessory`, { headers });
};

export const getPrimaryFaults = async () => {
  const headers = await getAuthHeaders();
  return axios.get(`${API_BASE_URL}/api/unit/fault`, { headers });
};

export const getMceReasons = async () => {
  const headers = await getAuthHeaders();
  return axios.get(`${API_BASE_URL}/api/lookup/mce`, { headers });
};

export const getSecondaryFaults = async (primary: string) => {
  const headers = await getAuthHeaders();
  return axios.get(`${API_BASE_URL}/api/unit?primary=${primary}`, { headers });
};

export const getPrimaryFaultsAcc = async () => {
  const headers = await getAuthHeaders();
  return axios.get(`${API_BASE_URL}/api/Accessory/fault`, { headers });
};

export const getSecondaryFaultsAcc = async (primary: string) => {
  const headers = await getAuthHeaders();
  return axios.get(`${API_BASE_URL}/api/accessory/fault?primary=${primary}`, { headers });
};

export const postUnit = async (data: unknown) => {
  const headers = await getAuthHeaders();
  return axios.post(`${API_BASE_URL}/api/unit`, data, { headers });
};

export const postAccessory = async (data: unknown) => {
  const headers = await getAuthHeaders();
  return axios.post(`${API_BASE_URL}/api/accessory`, data, { headers });
};

export const postDonation = async (data: unknown) => {
  const headers = await getAuthHeaders();
  return axios.post(`${API_BASE_URL}/api/donations/unit`, data, { headers });
};