import React, { useEffect } from 'react';
import { Alert, AlertTitle, Button, Checkbox, Chip, Divider, FormControlLabel, MenuItem, Stack, TextField, Typography } from '@mui/material';
import Grid from "@mui/material/Grid2"; // Grid2 import
import { FormikProps, getIn } from 'formik';
import { FormValues, initialValues } from '../../containers/Main';
import { getPrimaryFaults, getSecondaryFaults, getIMEI, unitLocked, getMceReasons } from '../../services/apiService';
import { AxiosError } from 'axios';

interface BookUnitProps {
  formik: FormikProps<FormValues>;
}

interface Fault {
  Primary: string
  Secondary: string
  Definition?: string
}

interface DropDowns {
  primaryFaults: Fault[];
  secondaryFaults: Fault[];
  mceReasons: string[]
}

const BookUnit: React.FC<BookUnitProps> = ({ formik }) => {
  const { values, touched, errors, handleChange, handleBlur, setFieldValue } = formik;
  const [dropDowns, setDropDowns] = React.useState<DropDowns>({ primaryFaults: [], secondaryFaults: [], mceReasons: [] });
  const [locked, setLocked] = React.useState()
  const [imeiError, setimeiError] = React.useState<string>('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const primaryFaultsResponse = await getPrimaryFaults();
        setDropDowns((prev) => ({ ...prev, primaryFaults: primaryFaultsResponse.data }));
      } catch (err) {
        console.error('Error fetching primary faults:', err);
      }

      try {
        const mceReasonsResponse = await getMceReasons();
        setDropDowns((prev) => ({ ...prev, mceReasons: mceReasonsResponse.data }));
      } catch (err) {
        console.error('Error fetching mce reasons:', err);
      }
    };
    formik.validateForm()
    fetchData();
  }, []);

  // Fetch secondary faults when PrimaryFaultCode changes
  useEffect(() => {
    const fetchSecondaryFaults = async () => {
      if (!values.PrimaryFaultCode) return; // Avoid API call if PrimaryFaultCode is empty

      try {
        const response = await getSecondaryFaults(values.PrimaryFaultCode);
        setDropDowns((prev) => ({ ...prev, secondaryFaults: response.data }));
      } catch (err) {
        console.error('Error fetching secondary faults:', err);
      }
    };

    fetchSecondaryFaults();
  }, [values.PrimaryFaultCode]); // Trigger when PrimaryFaultCode changes

  // useEffect(() => {
  //   if (locked === 'Yes' || imeiError !== '') {
  //     formik.setErrors({ IMEI: locked === 'Yes' ? "The device may be locked" : "Error finding the device from the IMEI"})
  //   }
  // }, [locked, imeiError])

  const searchIMEI = async () => {
    try {
      const IMEIresponse = await getIMEI(values.IMEI);
      formik.setValues({
        ...formik.values,
        PhoneDetails: IMEIresponse.data
      })
      setimeiError('')
      await lockCheck()
    } catch (err) {
      formik.setValues({
        ...formik.values,
        PhoneDetails: initialValues.PhoneDetails,
        IMEI: ''
      })
      if (err instanceof AxiosError) {
        console.log(err.response?.data)
        formik.setErrors({ IMEI: locked === 'Yes' ? "The device may be locked" : err.response?.data || "Error finding the device from the IMEI" })
        setimeiError(err.response?.data ?? 'Error finding the device from the IMEI')
      }
      console.error('Error fetching IMEI:', err);
    } finally {
      formik.setFieldValue("searchClicked", true);
    }
  }

  const lockCheck = async () => {
    try {
      const lockResponse = await unitLocked(values.IMEI);
      setLocked(lockResponse.data)
    } catch (err) {
      setLocked(undefined)
      console.error('Error checking Lock status:', err);
    }
  }

  // Calculate today's date and the minimum date (30 days ago) in YYYY-MM-DD format.
  const today = new Date();
  const todayString = today.toISOString().split('T')[0];

  const minDate = new Date();
  minDate.setDate(today.getDate() - 30);
  const minDateString = minDate.toISOString().split('T')[0];

  return (
    <Grid container spacing={2} sx={{ marginTop: 2 }}>
      <Grid size={{ xs: 12, sm: 3 }}>
        <TextField
          fullWidth
          id="IMEI"
          name="IMEI"
          label="IMEI"
          value={values.IMEI}
          slotProps={{
            inputLabel: { shrink: true },
            input: { inputProps: { maxLength: 15 } }
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.IMEI && Boolean(errors.IMEI)}
          helperText={touched.IMEI && errors.IMEI}
        />
        {values.PhoneDetails.Description.length > 0 &&
          <Stack direction="row" spacing={2} marginTop={2}>
            <Chip label={values.PhoneDetails.Description} />
          </Stack>
        }
      </Grid>
      <Grid size={{ xs: 12, sm: 9 }}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => searchIMEI()}
          disabled={values.IMEI === '' || values.IMEI.length !== 15}
        >
          Search
        </Button>
        {formik.errors.searchClicked && <Typography className="bodyText" style={{ color: 'red' }}>{formik.errors.searchClicked}</Typography>}
      </Grid>
      {values.PhoneDetails.SOTFBundle.length > 0 &&
        <>
          {values.PhoneDetails.SOTFBundle.map((item, index) => {
            return (
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.PhoneDetails.SOTFBundle[index].Returned}
                      onChange={(e) => setFieldValue(`PhoneDetails.SOTFBundle.${index}.Returned`, e.target.checked)}
                      name={`PhoneDetails.SOTFBundle.${index}.Returned`}
                      color="primary"
                    />
                  }
                  label={values.PhoneDetails.SOTFBundle[index].Description}
                />
                {item.SerialRequired && values.PhoneDetails.SOTFBundle[index].Returned ?
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <TextField
                      fullWidth
                      id={`PhoneDetails.SOTFBundle.${index}.SerialNumber`}
                      name={`PhoneDetails.SOTFBundle.${index}.SerialNumber`}
                      label="Enter Serial Number:"
                      required
                      value={values.PhoneDetails.SOTFBundle[index].SerialNumber}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        Boolean(
                          getIn(touched, `PhoneDetails.SOTFBundle[${index}].SerialNumber`) &&
                          getIn(errors, `PhoneDetails.SOTFBundle[${index}].SerialNumber`)
                        )
                      }
                      helperText={
                        getIn(touched, `PhoneDetails.SOTFBundle[${index}].SerialNumber`) &&
                        getIn(errors, `PhoneDetails.SOTFBundle[${index}].SerialNumber`)
                      }
                    />
                  </Grid>
                  : <></>
                }
              </Grid>
            )
          })}
        </>        
      }
      {/* error messages */}
      <Grid size={12}>
        {
          values.PhoneDetails.SmartPhone === 'Yes' && values.PhoneDetails.MCE === 'No' &&
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            Please note this device should be fully data cleared for GDPR compliance purposes.<br />

            If the MCE process has been unsuccessful please try to manually clear the device before continuing the booking.<br />

            This booking will be flagged to the Phone Shop manager for review.<br />
          </Alert>
        }
        {
          locked === 'Yes' &&
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            We have detected that find my iphone is turned on. It must be turned off before you can proceed. Please go to device help if you require any support on ‘Find my iphone’ <a href="https://tmservice.my.salesforce.com/">https://tmservice.my.salesforce.com/</a>
          </Alert>
        }
        {
          locked === 'Unknown' &&
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            Unable to automatically check lock status of the device, please ensure phone is not locked before completing the booking.
          </Alert>
        }
        {
          imeiError !== '' &&
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {imeiError}
          </Alert>
        }
      </Grid>
      {/* MCE flow */}
      {(values.PhoneDetails.SmartPhone === 'Yes' && values.PhoneDetails.MCE === 'No') &&
        <>
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }}>
            <TextField
              fullWidth
              id="MCEReason"
              select
              label="Select non MCE reason"
              required
              value={values.MCEReason}
              onChange={handleChange}
              onBlur={handleBlur}
              name="MCEReason"
              slotProps={{
                inputLabel: {
                  shrink: true, // Prevent the label from moving up
                },
              }}
              error={touched.MCEReason && Boolean(errors.MCEReason)}
              helperText={touched.MCEReason && errors.MCEReason}
            >
              {dropDowns.mceReasons.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid size={{ xs: 12, sm: 9 }}>
            <TextField
              fullWidth
              id="MCENotes"
              name="MCENotes"
              label="Notes"
              required
              multiline
              rows={3}
              value={values.MCENotes}
              slotProps={{
                inputLabel: {
                  shrink: true, // Prevent the label from moving up
                },
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.MCENotes && Boolean(errors.MCENotes)}
              helperText={touched.MCENotes && errors.MCENotes}
            />
          </Grid>
          {values.MCEReason.includes('MCE') &&
            <>
              <Grid size={{ xs: 12, sm: 3 }}>
                <TextField
                  fullWidth
                  id="ItTicketRef"
                  name="ItTicketRef"
                  label="IT ticket reference number"
                  required
                  value={values.ItTicketRef}
                  slotProps={{
                    inputLabel: {
                      shrink: true, // Prevent the label from moving up
                    },
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ItTicketRef && Boolean(errors.ItTicketRef)}
                  helperText={touched.ItTicketRef && errors.ItTicketRef}
                />
              </Grid>
              <Grid size={{ xs: 0, sm: 9 }} />
            </>
          }
          <Grid size={{ xs: 12, sm: 3 }}>
            <TextField
              fullWidth
              id="MCEtried"
              select
              label="How many times was MCE tried?"
              required
              value={values.MCEtried}
              onChange={handleChange}
              onBlur={handleBlur}
              name="MCEtried"
              slotProps={{
                inputLabel: {
                  shrink: true, // Prevent the label from moving up
                },
              }}
              error={touched.MCEtried && Boolean(errors.MCEtried)}
              helperText={touched.MCEtried && errors.MCEtried}
            >
              <MenuItem key="1" value="1">
                1
              </MenuItem>
              <MenuItem key="2" value="2">
                2
              </MenuItem>
              <MenuItem key="3+" value="3+">
                3+
              </MenuItem>
            </TextField>
          </Grid>
          <Grid size={{ xs: 0, sm: 9 }} />
          <Grid size={{ xs: 12, sm: 3 }}>
            <TextField
              fullWidth
              id="MCEResetUnit"
              select
              label="Have you manually reset the unit?"
              required
              value={values.MCEResetUnit}
              onChange={handleChange}
              onBlur={handleBlur}
              name="MCEResetUnit"
              slotProps={{
                inputLabel: {
                  shrink: true, // Prevent the label from moving up
                },
              }}
              error={touched.MCEResetUnit && Boolean(errors.MCEResetUnit)}
              helperText={touched.MCEResetUnit && errors.MCEResetUnit}
            >
              <MenuItem key="Yes" value="Yes">
                Yes
              </MenuItem>
              <MenuItem key="No" value="No">
                No
              </MenuItem>
            </TextField>
          </Grid>
          {values.MCEResetUnit === "No" &&
            <>
              <Grid size={{ xs: 12, sm: 3 }}>
                <TextField
                  fullWidth
                  id="MCEResetReason"
                  select
                  label="Select reason."
                  required
                  value={values.MCEResetReason}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="MCEResetReason"
                  slotProps={{
                    inputLabel: {
                      shrink: true, // Prevent the label from moving up
                    },
                  }}
                  error={touched.MCEResetReason && Boolean(errors.MCEResetReason)}
                  helperText={touched.MCEResetReason && errors.MCEResetReason}
                >
                  {dropDowns.mceReasons.map((item) => (
                    item.includes('MCE') ? null :
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </>
          }
          <Grid size={{ xs: 0, sm: values.MCEResetUnit === "No" ? 6 : 9 }} />
          <Grid size={{ xs: 12, sm: 3 }}>
            <TextField
              fullWidth
              id="MCEUnitCharge"
              select
              label="Did you charge the unit if no power?"
              required
              value={values.MCEUnitCharge}
              onChange={handleChange}
              onBlur={handleBlur}
              name="MCEUnitCharge"
              slotProps={{
                inputLabel: {
                  shrink: true, // Prevent the label from moving up
                },
              }}
              error={touched.MCEUnitCharge && Boolean(errors.MCEUnitCharge)}
              helperText={touched.MCEUnitCharge && errors.MCEUnitCharge}
            >
              <MenuItem key="Yes" value="Yes">
                Yes
              </MenuItem>
              <MenuItem key="No" value="No">
                No
              </MenuItem>
            </TextField>
          </Grid>
          {values.MCEUnitCharge === "Yes" &&
            <>
              <Grid size={{ xs: 12, sm: 3 }}>
                <TextField
                  fullWidth
                  id="MCETwomins"
                  select
                  label="Was it for more than 2 minutes?"
                  required
                  value={values.MCETwomins}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="MCETwomins"
                  slotProps={{
                    inputLabel: {
                      shrink: true, // Prevent the label from moving up
                    },
                  }}
                  error={touched.MCETwomins && Boolean(errors.MCETwomins)}
                  helperText={touched.MCETwomins && errors.MCETwomins}
                >
                  <MenuItem key="Yes" value="Yes">
                    Yes
                  </MenuItem>
                  <MenuItem key="No" value="No">
                    No
                  </MenuItem>
                </TextField>
              </Grid>
            </>
          }
        </>
      }
      {/* Bundle in description */}
      {values.PhoneDetails.Description.toLowerCase().includes('bundle') &&
        <>
          <Grid size={12} />
          <Grid size={{ xs: 12, sm: 3 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.AcceptBundle}
                  onChange={(e) => setFieldValue('AcceptBundle', e.target.checked)}
                  name="AcceptBundle"
                  color="primary"
                  required
                />
              }
              label="Bundle item recieved?"

            />
          </Grid>
          {values.AcceptBundle &&
            <Grid size={{ xs: 12, sm: 3 }}>
              <TextField
                fullWidth
                id="BundleSerial"
                name="BundleSerial"
                label="Enter the Bundle item serial number"
                required
                value={values.BundleSerial}
                slotProps={{
                  inputLabel: {
                    shrink: true, // Prevent the label from moving up
                  },
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.BundleSerial && Boolean(errors.BundleSerial)}
                helperText={touched.BundleSerial && errors.BundleSerial}
              />
            </Grid>
          }
        </>
      }
      {/* Standard form */}
      <Grid size={12}>
        <Divider />
      </Grid>
      <Grid size={12} />
      {values.Channel.includes("PAYM") &&
        <Grid size={{ xs: 12, sm: 3 }}>
          <TextField
            fullWidth
            id="OrderNumber"
            name="OrderNumber"
            required
            label="Order Number"
            value={values.OrderNumber}
            slotProps={{
              inputLabel: {
                shrink: true, // Prevent the label from moving up
              },
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.OrderNumber && Boolean(errors.OrderNumber)}
            helperText={touched.OrderNumber && errors.OrderNumber}
          />
        </Grid>
      }
      {/* Tote Numbers */}
      <Grid size={{ xs: 12, sm: 3 }}>
        <TextField
          fullWidth
          id="ToteNum"
          name="ToteNum"
          label="Seal 1"
          required
          value={values.ToteNum}
          slotProps={{
            inputLabel: {
              shrink: true, // Prevent the label from moving up
            },
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.ToteNum && Boolean(errors.ToteNum)}
          helperText={touched.ToteNum && errors.ToteNum}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 3 }}>
        <TextField
          fullWidth
          id="OtherTote"
          name="OtherTote"
          label="Seal 2"
          value={values.OtherTote}
          slotProps={{
            inputLabel: {
              shrink: true, // Prevent the label from moving up
            },
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          error={touched.OtherTote && Boolean(errors.OtherTote)}
          helperText={touched.OtherTote && errors.OtherTote}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 3 }}>
        <TextField
          fullWidth
          id="SaleDate"
          name="SaleDate"
          label="Sale Date"
          type="date"
          required
          value={values.SaleDate}
          slotProps={{
            input: {
              inputProps: { min: minDateString, max: todayString },
            },
            inputLabel: {
              shrink: true,
            },
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.SaleDate && Boolean(errors.SaleDate)}
          helperText={touched.SaleDate && errors.SaleDate}
        />
      </Grid>
      {!values.Channel.includes("PAYM") &&
        <Grid size={{ xs: 12, sm: 3 }} />
      }
      <Grid size={{ xs: 12, sm: 3 }}>
        <TextField
          fullWidth
          id="PrimaryFaultCode"
          select
          label="Primary Fault Code"
          required
          value={values.PrimaryFaultCode}
          onChange={handleChange}
          onBlur={handleBlur}
          name="PrimaryFaultCode"
          slotProps={{
            inputLabel: {
              shrink: true, // Prevent the label from moving up
            },
          }}
          error={touched.PrimaryFaultCode && Boolean(errors.PrimaryFaultCode)}
          helperText={touched.PrimaryFaultCode && errors.PrimaryFaultCode}
        >
          {dropDowns.primaryFaults.map((item) => (
            <MenuItem key={item.Primary} value={item.Primary}>
              {item.Primary}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {values.PrimaryFaultCode !== '' &&
        <Grid size={{ xs: 12, sm: 3 }}>
          <TextField
            fullWidth
            id="SecondaryFaultCode"
            select
            label="Secondary Fault Code"
            required
            value={values.SecondaryFaultCode}
            onChange={handleChange}
            onBlur={handleBlur}
            name="SecondaryFaultCode"
            slotProps={{
              inputLabel: {
                shrink: true, // Prevent the label from moving up
              },
            }}
            error={touched.SecondaryFaultCode && Boolean(errors.SecondaryFaultCode)}
            helperText={touched.SecondaryFaultCode && errors.SecondaryFaultCode}
          >
            {dropDowns.secondaryFaults.map((item) => (
              <MenuItem key={item.Secondary} value={item.Secondary}>
                {item.Secondary}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      }
      <Grid size={{ xs: 12, sm: 6 }} />
      <Grid size={{ xs: 12, sm: 9 }}>
        <TextField
          fullWidth
          id="FreeText"
          name="FreeText"
          label="Additional Information"
          multiline
          rows={6}
          value={values.FreeText}
          slotProps={{
            inputLabel: {
              shrink: true, // Prevent the label from moving up
            },
          }}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
    </Grid>
  );
};

export default BookUnit;
