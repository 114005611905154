import React, { useEffect } from 'react';
import { TextField, Box, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import Grid from "@mui/material/Grid2"; // Grid2 import
import { FormikProps, useFormikContext } from 'formik';
import { FormValues } from '../../containers/Main';
import { getstores } from '../../services/apiService';

interface ReturnPolicyProps {
  formik: FormikProps<FormValues>;
}

interface DropDowns {
  stores: string[];
  channel: string[];
  bookingType: string[]
}

// A helper component that watches bookingType and updates channel
const UpdateChannelForAccessory = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  useEffect(() => {
    if (values.BookingType === "Book Accessory") {
      // Set channel value to the first dropdown item (or any preset value you choose)
      setFieldValue("Channel", 'PAYG Return');
    }
    if (values.BookingType === "Donation") {
      // Set channel value to the first dropdown item (or any preset value you choose)
      setFieldValue("Channel", '');
    }
  }, [values.BookingType, setFieldValue]);

  return null;
};

const ReturnPolicy: React.FC<ReturnPolicyProps> = ({ formik }) => {
  const { values, touched, errors, handleChange, handleBlur, setFieldValue } = formik;
  const channel = ['PAYM Exchange Return', 'PAYM Cancellation', 'PAYG Return']
  const bookingType = ['Book Phone', 'Book Accessory']
  const [dropDowns, setDropDowns] = React.useState<DropDowns>({ stores: [], channel, bookingType });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storesResponse = await getstores();
        setDropDowns((prev) => ({ ...prev, stores: storesResponse.data || [] }));
      } catch (err) {
        console.error('Error fetching stores:', err);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2} sx={{ marginTop: 2 }}>
      {/* Left Column: Scrollable Text */}
      <Grid size={{ xs: 12, sm: 6 }}>
        <Box
          sx={{
            height: '400px', // Adjust as needed
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: 2,
            // Firefox styling
            scrollbarWidth: 'thin',
            scrollbarColor: '#bdbdbd #f1f1f1',
            // Webkit styling
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#bdbdbd',
              borderRadius: '4px',
              border: '2px solid #f1f1f1',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#9e9e9e',
            },
          }}
        >
          <p className="pageTitles" style={{ marginTop: 0, marginBottom: 20 }}>
            TESCO MOBILE RETURNS PROCESS
          </p>
          <p
            className="pageSubTitles"
            style={{ marginTop: 0, marginBottom: 40 }}
          >
            Tesco return date policy
          </p>

          <p
            className="headings"
          >
            Accessory
          </p>

          <ul style={{ listStyle: "none" }}>
            <li className="bodyText" >If you would like to book an accessory please select the "Book Accessory" booking type</li>
          </ul>

          <ul style={{ listStyle: "none" }}>
            <li className="bodyText" >Change of Mind – 30 days from purchase Manufacturer Faulty</li>
            <br />
            <li className="bodyText" >Manufacturer Faulty – 30 days from purchase</li>
          </ul>

          <p
            className="headings"
          >
            Pay As You Go
          </p>

          <ul style={{ listStyle: "none" }}>
            <li className="bodyText" >Change of Mind – 30 days from purchase Manufacturer Faulty</li>
            <br />
            <li className="bodyText" >Manufacturer Faulty – 30 days from purchase</li>
          </ul>

          <p
            className="headings"
          >
            Pay Monthly
          </p>

          <ul style={{ listStyle: "none" }}>
            <li className="bodyText" >Change of Mind – 14 days from purchase</li>
            <br />
            <li className="bodyText" >Manufacturer Faulty – 30 days from purchase</li>
          </ul>

          <p
            className="headings"
          >
            Return Criteria
          </p>

          <ul>
            <li>
              <p className="bodyText" >
                <b>Within return policy</b> by sales and return date (as above)
              </p>
            </li>
            <li>
              <p className="bodyText" >
                <b>As sold condition.</b> Including original box and any
                accessories
              </p>
            </li>
            <li>
              <p className="bodyText" >
                <b>No physical damage.</b> Devices should not be returned if it
                has any signs of physical damage (e.g. smashed screen). This
                includes water damages.
              </p>
            </li>
            <li>
              <p className="bodyText" >
                <b>All Android, FMI and PIN locks must be removed</b>
              </p>
            </li>
            <li>
              <p className="bodyText" >
                <b>Please make sure all sims and e-sim data is removed from the device where applicable.</b>
              </p>
            </li>
            <li>
              <p className="bodyText" >
                <b style={{ color: "red" }}>Factory Reset.</b> All customer data
                on the device must be removed before the device is returned.
                Please refer to the factory reset process in e-learning for any
                additional support required. Device help link - <a href="https://tmservice.my.salesforce.com/">
                  https://support.tescomobile.com/customer/picker.html
                </a>
              </p>
            </li>
          </ul>
          <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.ReturnBooking}
                      onChange={(e) => setFieldValue('ReturnBooking', e.target.checked)}
                      name="return booking"
                      color="primary"
                      required
                    />
                  }
                  label="Return Booking"
                  
                />
        </Box>
      </Grid>

      {/* Right Column: Form Fields */}
      <Grid size={{ xs: 12, sm: 6 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <TextField
              fullWidth
              id="Store"
              select
              label="Select Store ID"
              value={values.Store}
              onChange={handleChange}
              onBlur={handleBlur}
              name="Store"
              slotProps={{
                inputLabel: {
                  shrink: true, // Prevent the label from moving up
                },
              }}
              error={touched.Store && Boolean(errors.Store)}
              helperText={touched.Store && errors.Store}
            >
              {dropDowns.stores.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid size={{ xs: 12}}>
            <TextField
            fullWidth
              id="BookingType"
              select
              label="Booking Type"
              value={values.BookingType}
              onChange={handleChange}
              onBlur={handleBlur}
              name="BookingType"
              slotProps={{
                inputLabel: {
                  shrink: true, // Prevent the label from moving up
                },
              }}
              error={touched.BookingType && Boolean(errors.BookingType)}
              helperText={touched.BookingType && errors.BookingType}
            >
              {dropDowns.bookingType.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <UpdateChannelForAccessory />
          <Grid size={{ xs: 12}}>
            <TextField
            fullWidth
              id="Channel"
              select
              label="Channel"
              value={values.Channel}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={values.BookingType !== 'Book Phone'}
              name="Channel"
              variant='outlined'
              slotProps={{
                inputLabel: {
                  shrink: true, // Prevent the label from moving up
                },
              }}
              error={touched.Channel && Boolean(errors.Channel)}
              helperText={touched.Channel && errors.Channel}
            >
              {dropDowns.channel.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReturnPolicy;
