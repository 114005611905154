import { AppBar, Toolbar, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";

interface LayoutProps {
  user: any;
  onSignOut: () => void;
}

const Navbar: React.FC<LayoutProps>  = ({ user, onSignOut }) => {
    return (
      <AppBar position="static" color="primary">
        <Toolbar>
          <Button color="inherit" component="a" href="/">Home</Button>
          <Button color="inherit" component={Link} to="/booked">Booked</Button>
          <Button color="inherit" component={Link} to="/collections">Collections</Button>
          {user && (
             <Box sx={{ ml: "auto" }}>
            <Button color="inherit" onClick={onSignOut} sx={{ alignItems: 'right'}}>
              Sign Out
            </Button></Box>
          )}
        </Toolbar>
      </AppBar>
    );
  };

  export default Navbar