import React from 'react';
import { Outlet } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import TescoLogo from '../assets/TES_MOB_Primary_Logo_RedBlue_RGB.svg';
import CeLogo from '../assets/tlxlogo.png';
import Navbar from './Navbar';

interface LayoutProps {
  user: any;
  onSignOut: () => void;
}

const Layout: React.FC<LayoutProps> = ({ user, onSignOut }) => {
  return (
    <>
      <AppBar position="static" style={{ backgroundColor: 'white' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            <img src={TescoLogo} alt='Tesco Mobile Logo' className='tescoLogo' />
            <img src={CeLogo} alt='Cheapest Electrical Logo' className='ceLogo' />
          </Typography>
        </Toolbar>
      </AppBar>
      <Navbar user={user} onSignOut={onSignOut} />
      <Box sx={{ mt: 2 }}>
        <Outlet />
      </Box>
    </>
  );
};

export default Layout;
