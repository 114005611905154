import { Container, Card, CardActionArea, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2"; // Grid2 import
import { useNavigate } from "react-router-dom";

const LandingPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Container
            maxWidth="xl"
            sx={{
                marginTop: 2,
                height: "60vh", // Container takes 80% of viewport height
                width: "100vw",
                display: "flex",
                borderRadius: "4px",
                padding: 3,
            }}
        >
            <Grid container spacing={2} sx={{ height: "100%", width: "100%" }}>
                {/* Left Card */}
                <Grid size={6}>
                    <Card
                        sx={{
                            height: "100%",
                            "&:hover": {
                                boxShadow: '0 0 0 4px rgba(0, 83, 159, .4)',
                            },
                            backgroundColor: 'white',
                            cursor: "pointer",
                        }}
                        raised
                        onClick={() => navigate("/return")}
                    >
                        <CardActionArea disableRipple sx={{ height: "100%", textAlign: "center", padding: 3 }}>
                            <Typography variant="h5" color="black">
                                Book Return
                            </Typography>
                        </CardActionArea>
                    </Card>
                </Grid>

                {/* Right Card */}
                <Grid size={6}>
                    <Card
                        sx={{
                            height: "100%",
                            "&:hover": {
                                boxShadow: '0 0 0 4px rgba(0, 83, 159, .4)',
                            },
                            cursor: "pointer",
                        }}
                        raised
                        onClick={() => navigate("/recycle")}
                    >
                        <CardActionArea disableRipple sx={{ height: "100%", textAlign: "center", padding: 3 }}>
                            <Typography variant="h5" color="black">
                                Book Recycle
                            </Typography>
                            <Typography variant="subtitle1" color="red">
                                Recycling is for trial stores only - 2371 Durham and 2278 Chester-le-Street
                            </Typography>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default LandingPage;
