import React from 'react';
import { Container, Stepper, Step, StepLabel, Button, Paper } from '@mui/material';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import ReturnPolicy from '../components/unitStepper/ReturnPolicy';
import BookUnit from '../components/unitStepper/BookUnit';
import BookAccessory from '../components/unitStepper/BookAccessory';
import { postAccessory, postUnit } from '../services/apiService';
import UserFeedback from '../components/UserFeedback';
import axios from "axios";

interface SoftBundleItem {
  Description: string;
  SerialRequired: boolean;
  Returned: boolean;
  SerialNumber: string;
}

export interface IMEIresponse {
  Description: string;
  IMEI: string;
  MCE: string;
  Manufacturer: string;
  PONumber?: string;
  SOTFBundle: SoftBundleItem[];
  SmartPhone: string;
  TPNB: string
}

export interface FormValues {
  IMEI: string;
  searchClicked: boolean;
  EAN: string;
  TPNB: string;
  PhoneDetails: IMEIresponse;
  TPNBDesc: string;
  Manufacturer: string;
  Model: string;
  SaleDate: string; // formatted as YYYY-MM-DD
  PrimaryFaultCode: string;
  SecondaryFaultCode: string;
  FreeText: string;
  Store: string;
  Channel: string;
  Bundle: string;
  BundleSerial: string;
  Useremail: string;
  OrderNumber: string;
  ToteNum: string;
  OtherTote: string;
  AndroidLock: boolean;
  Damaged: boolean;
  EverythingReturned: boolean;
  AcceptBundle: boolean;
  ReturnBooking: boolean;
  validImei: boolean;
  BookingType: string;
  ModelAcc: string;
  SaleDateAcc: string; // formatted as YYYY-MM-DD
  PrimaryFaultCodeAcc: string;
  SecondaryFaultCodeAcc: string;
  FreeTextAcc: string;
  MceBooked: string;
  MCEReason: string;
  ItTicketRef: string;
  MCENotes: string;
  MCEtried: string;
  MCEUnitCharge: string;
  MCETwomins: string;
  MCEResetUnit: string;
  MCEResetReason: string;
  ManufacturerDonation: string;
  FreeTextDonation: string;
  IMEIdonation: string;
  ModelDonation: string;
  ReasonDonation: string;
  MceCheckDonation: boolean;
}

const steps = ['Tesco Mobile Returns Policy', 'Book Unit for Return'];

const getValidationSchema = (values: FormValues, activeStep: number) => {
  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);
  if (values.BookingType === 'Book Phone' && activeStep !== 0) {
    const mceCheck: boolean = values.PhoneDetails.SmartPhone === 'Yes' && values.PhoneDetails.MCE === 'No'
    return Yup.object().shape({
      IMEI: Yup.string().matches(/^\d+$/, "IMEI must be a number")
        .length(15, "IMEI must be exactly 15 digits")
        .required("IMEI is required"),
        searchClicked: Yup.boolean()
        .oneOf([true], "Search must be performed before submitting"),
      OrderNumber: values.Channel.includes("PAYG") ? Yup.string().optional() : Yup.string().required("Order number is required"),
      ToteNum: Yup.string().required("Enter Seal 1 Number"),
      OtherTote: Yup.string().required("Enter Seal 2 Number"),
      SaleDate: Yup.date()
        .required("Date is required")
        .max(today, "Date must be in the past")
        .min(thirtyDaysAgo, "Date must not be more than 30 days in the past"),
      PrimaryFaultCode: Yup.string().required("Primary fault must be selected"),
      SecondaryFaultCode: Yup.string().required("Secondary fault must be selected"),
      FreeText: Yup.string().optional(),
      MCEReason: mceCheck ? Yup.string().required("Provide a reason why unable to book through MCE") : Yup.string().optional(),
      MCENotes: mceCheck ? Yup.string()
        .min(30, "Must be at least 30 characters long")
        .matches(/^[A-Za-z\s]+$/, "Must not contain special characters or numbers")
        .matches(/^(?!.*(.)\1\1)/, "No more than 2 successive identical characters allowed").required() : Yup.string().optional(),
      ItTicketRef: mceCheck && values.MCEReason.includes('MCE') ? Yup.string().required("Enter the 8 digit IT ticket reference number.") : Yup.string().optional(),
      MCEtried: mceCheck ? Yup.string().required() : Yup.string().optional(),
      MCEResetUnit: mceCheck ? Yup.string().required() : Yup.string().optional(),
      MCEResetReason: mceCheck && values.MCEResetUnit === "No" ? Yup.string().required() : Yup.string().optional(),
      MCEUnitCharge: mceCheck ? Yup.string().required() : Yup.string().optional(),
      MCETwomins: mceCheck && values.MCEUnitCharge === "Yes" ? Yup.string().required() : Yup.string().optional(),
      AcceptBundle: values.PhoneDetails.Description.toLowerCase().includes('bundle') ? Yup.boolean().oneOf([true], 'Tick to confirm you have read the above.') : Yup.boolean(),
      BundleSerial: values.AcceptBundle ? Yup.string().required("Please provide the bundle item serial number").min(5, 'Must be at least 5 characters')
      .max(15, 'Must be at most 15 characters').matches(/\d/, 'Must contain at least one digit') : Yup.string().optional(),
      PhoneDetails: Yup.object({
        SOTFBundle: Yup.array().of(
          Yup.object({
            SerialRequired: Yup.boolean(),
            Returned: Yup.boolean().required("Returned flag is required"),
            SerialNumber: Yup.string().when(['Returned', 'SerialRequired'], ([Returned, SerialRequired], schema) => (Returned === true && SerialRequired === true) ? schema.required('Serial Number is required') : schema.optional()),
          })
        ),
      }),
    })
  } else if (values.BookingType === 'Book Accessory' && activeStep !== 0) {
    return Yup.object().shape({
      ModelAcc: Yup.string().required('Model is required'),
      SaleDateAcc: Yup.date()
        .required("Date is required")
        .max(today, "Date must be in the past")
        .min(thirtyDaysAgo, "Date must not be more than 30 days in the past"),
      ToteNum: Yup.string().required("Enter Seal 1 Number"),
      OtherTote: Yup.string().required("Enter Seal 2 Number"),
      PrimaryFaultCodeAcc: Yup.string().required("Primary fault must be selected"),
      SecondaryFaultCodeAcc: Yup.string().required("Secondary fault must be selected"),
      FreeTextAcc: Yup.string().optional()
    })
  }
  return Yup.object().shape({
    Store: Yup.string().required('Store is required'),
    //Channel: Yup.string().required('Channel is required'),
    Channel: Yup.string().when(
      "BookingType", ([BookingType], schema) => (BookingType === "Donation") ? schema.optional() : schema.required("Select Channel")),
    BookingType: Yup.string().required('Booking type is required'),
    ReturnBooking: Yup.boolean().oneOf([true], 'Tick to confirm you have read the above.')
  })
}

export const initialValues: FormValues = {
  //IMEI: "358684091770866", // with bundle desciption
  //IMEI: "356134090985364", // SOFT IMEI
  IMEI: '',
  searchClicked: false, // Hidden field for validation
  EAN: "",
  PhoneDetails: {
    Description: '',
    IMEI: '',
    MCE: '',
    Manufacturer: '',
    PONumber: '',
    SOTFBundle: [],
    SmartPhone: '',
    TPNB: ''
  },
  TPNB: "",
  TPNBDesc: "",
  Manufacturer: "",
  Model: "",
  SaleDate: new Date(new Date().setUTCHours(0, 0, 0, 0))
    .toISOString()
    .substring(0, 10),
  PrimaryFaultCode: "",
  SecondaryFaultCode: "",
  FreeText: "",
  Store: "",
  ReturnBooking: false,
  AcceptBundle: false,
  Channel: "",
  Bundle: "",
  BundleSerial: "",
  Useremail: "",
  OrderNumber: "",
  ToteNum: "",
  OtherTote: "",
  AndroidLock: false,
  Damaged: false,
  EverythingReturned: false,
  validImei: false,
  BookingType: "Book Phone",
  ModelAcc: "",
  SaleDateAcc: new Date(new Date().setUTCHours(0, 0, 0, 0))
    .toISOString()
    .substring(0, 10),
  PrimaryFaultCodeAcc: "",
  SecondaryFaultCodeAcc: "",
  FreeTextAcc: "",
  MceBooked: "",
  MCEReason: "",
  ItTicketRef: "",
  MCENotes: "",
  MCEtried: "",
  MCEUnitCharge: "",
  MCETwomins: "",
  MCEResetUnit: "",
  MCEResetReason: "",
  ManufacturerDonation: '',
  FreeTextDonation: '',
  IMEIdonation: '',
  ModelDonation: '',
  ReasonDonation: '',
  MceCheckDonation: false,
};

const Main: React.FC<{ user: any }> = ({ user }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const isLastStep = activeStep === steps.length - 1;
  const [feedback, setFeedback] = React.useState({ open: false, message: "", severity: "success" });

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    if (isLastStep) {
      console.log(user)
      values.Useremail = user.username
      if (values.BookingType === 'Book Phone') {
        await bookPhone(values, actions)
      } else if (values.BookingType === 'Book Accessory') {
        await bookAccessory(values, actions)
      }
      // setActiveStep(0); don't return to home page
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const bookAccessory = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    const post = {
      Store: values.Store,
      TPNB: values.TPNB,
      userEmail: values.Useremail,
      PurchaseDate: values.SaleDateAcc,
      Seal1: values.ToteNum,
      Seal2: values.OtherTote,
      PrimaryReturnReason: values.PrimaryFaultCodeAcc,
      SecondaryReturnReason: values.SecondaryFaultCodeAcc,
      Notes: values.FreeTextAcc
    };
    // Final submission logic
    console.log('Submitted values:', post);

    try {
      actions.setSubmitting(true)
      await postAccessory(post);
      setFeedback({ open: true, message: "Accessory Booked", severity: "success" });
      setActiveStep(0)
      actions.resetForm({
        values: {
          ...initialValues,
          Store: values.Store
        }
      });
    } catch (err) {
      console.error('Error posting accessory:', err);
      if (axios.isAxiosError(err)) {
        setFeedback({ open: true, message: err.response?.data, severity: "error" });
        console.error("Axios error:", err.response?.data || err.message);
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  const bookPhone = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    const post = {
      IMEI: values.IMEI,
      EAN: values.EAN,
      TPNB: values.PhoneDetails.TPNB,
      TPNBDesc: values.TPNBDesc,
      SaleDate: values.SaleDate,
      Manufacturer: values.PhoneDetails.Manufacturer,
      Model: values.Model,
      PrimaryFaultCode: values.PrimaryFaultCode,
      SecondaryFaultCode: values.SecondaryFaultCode,
      FreeText: values.FreeText,
      OrderNumber: values.OrderNumber,
      ToteNum: values.ToteNum,
      Bundle: values.AcceptBundle ? "Y" : "",
      BundleSerial: values.BundleSerial,
      ToteSecondary: values.OtherTote,
      AndroidLock: values.AndroidLock,
      Damaged: values.Damaged,
      EverythingReturned: values.EverythingReturned,
      StoreID: values.Store,
      Channel: values.Channel,
      User: values.Useremail,
      bookingType: values.BookingType,
      SOTFBundle: values.PhoneDetails.SOTFBundle,
      MCE: {
        Smartphone: values.PhoneDetails.SmartPhone,
        MCEresult: values.PhoneDetails.MCE,
        MceBooked: values.MceBooked,
        details: {
          Reason: values.MCEReason,
          ItTicketRef: values.ItTicketRef,
          Notes: values.MCENotes,
          NumberOfTimes: values.MCEtried,
          Charged: values.MCEUnitCharge,
          MoreThan2Mins: values.MCETwomins,
          MCEResetUnit: values.MCEResetUnit,
          MCEResetReason: values.MCEResetReason
        }

      }
    };
    // Final submission logic
    console.log('Submitted values:', post);

    try {
      actions.setSubmitting(true)
      await postUnit(post);
      // Show success message
      setFeedback({ open: true, message: "Unit booked", severity: "success" });
      setActiveStep(0)
      actions.resetForm({
        values: {
          ...initialValues,
          Store: values.Store
        }
      });
    } catch (err) {
      // Show error message
      console.error('Error posting unit:', err);
      if (axios.isAxiosError(err)) {
        setFeedback({ open: true, message: err.response?.data, severity: "error" });
        console.error("Axios error:", err.response?.data || err.message);
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  const getBookingScreen = (formik: FormikProps<FormValues>) => {
    const type = formik.values.BookingType
    switch (type) {
      case 'Book Phone':
        return <BookUnit formik={formik} />
      case 'Book Accessory':
        return <BookAccessory formik={formik} />
      default:
        console.log('failed to render correct screen')
    }
  }

  return (
    <Container maxWidth="xl" disableGutters sx={{ marginTop: 4 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Container maxWidth="xl" sx={{ marginTop: 2, padding: 5, boxShadow: '2px 3px 8px 0 rgba(0, 0, 0, .2)', borderRadius: '4px', }} component={Paper}>
        <Formik
          initialValues={initialValues}
          //validationSchema={getValidationSchema(values, activeStep)}
          validate={(values) => {
            try {
              // Get the current schema based on values and run validation synchronously
              getValidationSchema(values, activeStep).validateSync(values, { abortEarly: false });
              return {}; // no errors
            } catch (err) {
              console.log(err)
              const errors: { [key: string]: string } = {};
              if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                  if (error.path) {
                    errors[error.path] = error.message;
                    console.log(error.message)
                  }
                });
              }
              return errors;
            }
          }}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {(formik) => (
            <Form>
              {activeStep === 0 && <ReturnPolicy formik={formik} />}
              {activeStep === 1 && getBookingScreen(formik)}
              <div style={{ marginTop: 16, paddingBottom: 8 }}>
                {activeStep > 0 && (
                  <Button
                    onClick={() => setActiveStep(activeStep - 1)}
                    variant="contained"
                    //color="secondary"
                    sx={{ marginRight: 2 }}
                  >
                    Back
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting || !formik.isValid}
                  sx={{ float: 'right' }}
                >
                  {isLastStep ? 'Submit' : 'Next'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <UserFeedback 
              open={feedback.open} 
              message={feedback.message} 
              severity={feedback.severity as "success" | "error"} 
              onClose={() => setFeedback({ ...feedback, open: false })} 
          />
      </Container>
    </Container>
  );
};

export default Main;
