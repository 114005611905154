import React, { useEffect } from 'react';
import { TextField, MenuItem } from '@mui/material';
import Grid from "@mui/material/Grid2"; // Grid2 import
import { FormikProps } from 'formik';
import { FormValues } from '../../containers/Main';
import { getModelsAcc, getPrimaryFaultsAcc, getSecondaryFaultsAcc } from '../../services/apiService';

interface BookAccessoryProps {
  formik: FormikProps<FormValues>;
}

interface Accessory {
  Description: string;
  TPNB: string;
  Unattach: number;
}

interface Fault {
  Primary: string
  Secondary: string
  Definition?: string
}

interface DropDowns {
  primaryFaults: Fault[];
  secondaryFaults: Fault[];
  models: Accessory[];
}

const BookAccessory: React.FC<BookAccessoryProps> = ({ formik }) => {
  const { values, touched, errors, handleChange, handleBlur } = formik;
  const [dropDowns, setDropDowns] = React.useState<DropDowns>({ primaryFaults: [], secondaryFaults: [], models: [] });

  useEffect(() => {
    formik.validateForm()
    const fetchData = async () => {
      try {
        const modelsResponse = await getModelsAcc();
        setDropDowns((prev) => ({ ...prev, models: modelsResponse.data }));
      } catch (err) {
        console.error('Error fetching accessories:', err);
      }

      try {
        const primaryFaultsResponse = await getPrimaryFaultsAcc();
        setDropDowns((prev) => ({ ...prev, primaryFaults: primaryFaultsResponse.data }));
      } catch (err) {
        console.error('Error fetching primary faults:', err);
      }
    };

    fetchData();
  }, []);

  // Fetch secondary faults when PrimaryFaultCode changes
  useEffect(() => {
    const fetchSecondaryFaults = async () => {
      if (!values.PrimaryFaultCodeAcc) return; // Avoid API call if PrimaryFaultCode is empty

      try {
        const response = await getSecondaryFaultsAcc(values.PrimaryFaultCodeAcc);
        setDropDowns((prev) => ({ ...prev, secondaryFaults: response.data }));
      } catch (err) {
        console.error('Error fetching secondary faults:', err);
      }
    };

    fetchSecondaryFaults();
  }, [values.PrimaryFaultCodeAcc]); // Trigger when PrimaryFaultCode changes

  // Fetch secondary faults when PrimaryFaultCode changes
  useEffect(() => {
    formik.setValues({
      ...formik.values,
      TPNB: dropDowns.models.find(m => m.Description === values.ModelAcc)?.TPNB || ''
    })
  }, [values.ModelAcc]); // Trigger when PrimaryFaultCode changes

  // Calculate today's date and the minimum date (30 days ago) in YYYY-MM-DD format.
  const today = new Date();
  const todayString = today.toISOString().split('T')[0];

  const minDate = new Date();
  minDate.setDate(today.getDate() - 30);
  const minDateString = minDate.toISOString().split('T')[0];

  return (
    <Grid container spacing={2} sx={{ marginTop: 2 }}>
      <Grid size={{ xs: 12, sm: 3 }}>
        <TextField
          fullWidth
          id="ModelAcc"
          select
          required
          label="Select Model"
          value={values.ModelAcc}
          onChange={handleChange}
          onBlur={handleBlur}
          name="ModelAcc"
          slotProps={{
            inputLabel: {
              shrink: true, // Prevent the label from moving up
            },
          }}
          error={touched.ModelAcc && Boolean(errors.ModelAcc)}
          helperText={touched.ModelAcc && errors.ModelAcc}
        >
          {dropDowns.models.map((item) => (
            <MenuItem key={item.Description} value={item.Description}>
              {item.Description}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid size={{ xs: 12, sm: 3 }}>
        <TextField
          fullWidth
          id="SaleDateAcc"
          name="SaleDateAcc"
          label="Sale Date"
          required
          type="date"
          value={values.SaleDateAcc}
          slotProps={{
            input: {
              inputProps: { min: minDateString, max: todayString },
            },
            inputLabel: {
              shrink: true,
            },
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.SaleDateAcc && Boolean(errors.SaleDateAcc)}
          helperText={touched.SaleDateAcc && errors.SaleDateAcc}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }} />
      <Grid size={{ xs: 12, sm: 3 }}>
        <TextField
          fullWidth
          id="ToteNum"
          name="ToteNum"
          required
          label="Seal 1"
          value={values.ToteNum}
          slotProps={{
            inputLabel: {
              shrink: true, // Prevent the label from moving up
            },
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.ToteNum && Boolean(errors.ToteNum)}
          helperText={touched.ToteNum && errors.ToteNum}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 3 }}>
        <TextField
          fullWidth
          id="OtherTote"
          name="OtherTote"
          required
          label="Seal 2"
          value={values.OtherTote}
          slotProps={{
            inputLabel: {
              shrink: true, // Prevent the label from moving up
            },
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.OtherTote && Boolean(errors.OtherTote)}
          helperText={touched.OtherTote && errors.OtherTote}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 3 }}>
        <TextField
          fullWidth
          id="PrimaryFaultCodeAcc"
          select
          label="Primary Fault Code"
          value={values.PrimaryFaultCodeAcc}
          onChange={handleChange}
          onBlur={handleBlur}
          name="PrimaryFaultCodeAcc"
          required
          slotProps={{
            inputLabel: {
              shrink: true, // Prevent the label from moving up
            },
          }}
          error={touched.PrimaryFaultCodeAcc && Boolean(errors.PrimaryFaultCodeAcc)}
          helperText={touched.PrimaryFaultCodeAcc && errors.PrimaryFaultCodeAcc}
        >
          {dropDowns.primaryFaults.map((item) => (
            <MenuItem key={item.Primary} value={item.Primary}>
              {item.Primary}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {values.PrimaryFaultCodeAcc !== '' &&

        <Grid size={{ xs: 12, sm: 3 }}>
          <TextField
            fullWidth
            id="SecondaryFaultCodeAcc"
            select
            label="Secondary Fault Code"
            value={values.SecondaryFaultCodeAcc}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            name="SecondaryFaultCodeAcc"
            slotProps={{
              inputLabel: {
                shrink: true, // Prevent the label from moving up
              },
            }}
            error={touched.SecondaryFaultCodeAcc && Boolean(errors.SecondaryFaultCodeAcc)}
            helperText={touched.SecondaryFaultCodeAcc && errors.SecondaryFaultCodeAcc}
          >
            {dropDowns.secondaryFaults.map((item) => (
              <MenuItem key={item.Secondary} value={item.Secondary}>
                {item.Secondary}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      }
      <Grid size={{ xs: 12, sm: 6 }}>
        <TextField
          fullWidth
          id="FreeTextAcc"
          name="FreeTextAcc"
          label="Additional Information"
          multiline
          rows={6}
          value={values.FreeTextAcc}
          slotProps={{
            inputLabel: {
              shrink: true, // Prevent the label from moving up
            },
          }}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
    </Grid>
  );
};

export default BookAccessory;
