import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { signInWithRedirect } from '@aws-amplify/auth';

const Login: React.FC = () => {
  const handleFederatedLogin = async (id: string) => {
    try {
      // Redirects to Cognito's Hosted UI for the selected provider
      await signInWithRedirect({ provider: { custom: id} });
    } catch (error) {
      console.error(`Error during sign-in with ${id}:`, error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Sign In
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleFederatedLogin('Tesco')}
          sx={{ m: 1 }}
        >
          Sign in with Tesco
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleFederatedLogin('TLX')}
          sx={{ m: 1 }}
        >
          Sign in with TLX
        </Button>
        { process.env.REACT_APP_LOCAL === 'true' &&
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleFederatedLogin('Local')}
          sx={{ m: 1 }}
        >
          Sign in local
        </Button>
        }
      </Box>
    </Container>
  );
};

export default Login;
