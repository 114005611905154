import React, { useEffect } from 'react';
import {
    TextField,
    MenuItem,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Container,
} from '@mui/material';
import { getstores, getStoreBookedDetails } from '../services/apiService';

interface Box {
    name: string;
    IMEI: string;
    Description: string;
    Manufacturer: string;
    TPNB: string;
}

interface DropDowns {
    stores: string[];
}

interface Values {
    Boxes: Box[];
}

interface Stores {
    store: string;
    email: string;
}


const Booked: React.FC = () => {

    const [dropDowns, setDropDowns] = React.useState<DropDowns>({ stores: [] });
    const [values, setValues] = React.useState<Values>({ Boxes: [] });
    const [stores, setStores] = React.useState<Stores>({ store: '', email: '' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storesResponse = await getstores();
                setDropDowns((prev) => ({ ...prev, stores: storesResponse.data }));
            } catch (err) {
                console.error('Error fetching stores:', err);
            }
        };

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange =
        (name: keyof Stores) => async (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setStores((prevStores) => ({ ...prevStores, [name]: value }));
            await getBoxes(value);
        };

    const getBoxes = async (store: string) => {
        try {
            const storesBookedResponse = await getStoreBookedDetails(store);
            setValues((prev) => ({ ...prev, Boxes: storesBookedResponse.data }));
        } catch (err) {
            setValues({ Boxes: []})
            console.error('Error fetching stores booked units:', err);
        }
    };

    const fieldStyles = { minWidth: "20%" };

    return (
        <Container component={Paper} maxWidth="xl" sx={{ marginTop: 2, padding: 5, boxShadow: '2px 3px 8px 0 rgba(0, 0, 0, .2)', borderRadius: '4px', }}>

            <TextField
                select
                label="Select Store ID"
                variant="outlined"
                value={stores.store}
                onChange={handleChange('store')}
                name="store"
                sx={fieldStyles}
                slotProps={{
                    inputLabel: {
                        shrink: true, // Prevent the label from moving up
                    },
                }}
                helperText="Select Store ID"
            >
                {dropDowns.stores.map((item) => (
                    <MenuItem key={item} value={item}>
                        {item}
                    </MenuItem>
                ))}
            </TextField>

            <Table sx={{ minWidth: 650, marginTop: 5 }} aria-label="Booked table" stickyHeader size="small">
                <TableHead>
                    <TableRow hover>
                        <TableCell>IMEI</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Manufacturer</TableCell>
                        <TableCell>TPNB</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {values.Boxes.map((row) => (
                        <TableRow key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>{row.IMEI}</TableCell>
                            <TableCell>{row.Description}</TableCell>
                            <TableCell>{row.Manufacturer}</TableCell>
                            <TableCell>{row.TPNB}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    );
};

export default Booked;
