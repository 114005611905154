import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import { getCurrentUser, signOut } from '@aws-amplify/auth';
import Layout from './components/Layout';
import Login from './components/Login';
import Main from './containers/Main';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/Navbar';
import Booked from './containers/Booked';
import Collection from './containers/Collection';
import LandingPage from './containers/LandingPage';
import Recycling from './containers/Recycling';

const awsConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
      userPoolClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID!,
      //identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID!,
    loginWith: {
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN!,
        redirectSignIn: [process.env.REACT_APP_COGNITO_REDIRECT_URI!],
        redirectSignOut: [process.env.REACT_APP_COGNITO_REDIRECT_URI!],
        responseType: 'token',
        scopes: ['email', 'openid'],
        providers: [
          {
            custom: "Tesco"
          },
          {
            custom: "TLX"
          },{
            custom: "Local"
          }]
      },
    },
    },
  },
};

Amplify.configure(awsConfig);

/**
 * App component: Manages authentication state, routing, and layout.
 */
const App: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);


  // Check for an authenticated user on mount
  useEffect(() => {
    const checkUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        setUser(currentUser);
        console.log(currentUser)
      } catch (error) {
        console.log(error)
        setUser(null);
      }  finally {
        setLoading(false); // Mark loading as finished regardless of outcome
      }
    };
    checkUser();
  }, []);

  // Sign-out handler
  const handleSignOut = async () => {
    try {
      await signOut();
      setUser(null);
      // After signing out, redirect to the login page.
      window.location.href = '/login';
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

    // If still loading, render a spinner or a loading message
    if (loading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <p>Loading...</p>
        </div>
      );
    }

  return (
    <BrowserRouter>
      <Routes>
        {/* The Layout wraps all routes to keep the AppBar persistent */}
        <Route element={<Layout user={user} onSignOut={handleSignOut} />}>
          {/* Public route: login page */}
          <Route path="/login" element={<Login />} />
          {/* Protected route: only accessible if user is logged in */}
          <Route element={<ProtectedRoute user={user} />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/return" element={<Main user={user} />} />
          <Route path="/recycle" element={<Recycling user={user} />} />
          <Route path="/booked" element={<Booked />} />
          <Route path="/collections" element={<Collection />} />
          </Route>
          {/* If no route matches, redirect to login */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;