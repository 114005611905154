import React, { useEffect } from 'react';
import { TextField, MenuItem, FormControlLabel, Checkbox, Typography } from '@mui/material';
import Grid from "@mui/material/Grid2"; // Grid2 import
import { FormikProps } from 'formik';
import { getDonationsManufacturer, getstores } from '../../services/apiService';
import { FormRecyclingValues } from '../../containers/Recycling';

interface RecycleProps {
    formik: FormikProps<FormRecyclingValues>;
}

interface DropDowns {
    manufacturer: string[];
    reasons: string[];
    stores: string[];
    resetReason: string[];
}

const reasons = [
    "No Power",
    "Unable To Connect",
    "Stuck In Boot Loop",
    "Smashed",
    "Damaged Device",
    "MCE Box Not Functioning"
]

const Recycle: React.FC<RecycleProps> = ({ formik }) => {
    const { values, touched, errors, handleChange, handleBlur, setFieldValue } = formik;
    const [dropDowns, setDropDowns] = React.useState<DropDowns>({ manufacturer: [], reasons, stores: [], resetReason: ['No Power', 'Damaged Device', 'Do not know how to reset'] });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const manufacturerResponse = await getDonationsManufacturer();
                setDropDowns((prev) => ({ ...prev, manufacturer: manufacturerResponse.data }));
            } catch (err) {
                console.error('Error fetching manufacturer:', err);
            }

            try {
                const storesResponse = await getstores();
                setDropDowns((prev) => ({ ...prev, stores: storesResponse.data.filter((store: string) => { if (store === '2371' || store === '2278') { return store } }) || [] }));
            } catch (err) {
                console.error('Error fetching stores:', err);
            }
        };
        formik.validateForm()
        fetchData();
    }, []);

    // Fetch secondary faults when MceCheckDonation changes
    useEffect(() => {
        if (!values.MceCheckDonation) {
            formik.setFieldValue('ReasonDonation', '')
        } else if (values.MceCheckDonation) {
            formik.setFieldValue('IMEIdonation', '')
        }
    }, [values.MceCheckDonation]); // Trigger when MceCheckDonation changes

    return (
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid size={{ xs: 12, sm: 3 }}>
                <TextField
                    fullWidth
                    id="Store"
                    select
                    required
                    label="Select Store ID"
                    value={values.Store}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="Store"
                    slotProps={{
                        inputLabel: {
                            shrink: true, // Prevent the label from moving up
                        },
                    }}
                    error={touched.Store && Boolean(errors.Store)}
                    helperText={touched.Store && errors.Store}
                >
                    {dropDowns.stores.map((item) => (
                        <MenuItem key={item} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid size={{ xs: 12, sm: 12 }} />
            <Grid size={{ xs: 12, sm: 3 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.MceCheckDonation}
                            onChange={(e) => setFieldValue('MceCheckDonation', e.target.checked)}
                            name="MceCheckDonation"
                            color="primary"
                        />
                    }
                    label="Tick if unable to find IMEI"
                />
            </Grid>
            {!values.MceCheckDonation &&
                <>
                    <Grid size={{ xs: 12, sm: 3 }}>
                        <TextField
                            fullWidth
                            id="IMEIdonation"
                            name="IMEIdonation"
                            label="IMEI"
                            required
                            value={values.IMEIdonation}
                            slotProps={{
                                inputLabel: { shrink: true },
                                input: { inputProps: { maxLength: 15 } }
                            }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.IMEIdonation && Boolean(errors.IMEIdonation)}
                            helperText={touched.IMEIdonation && errors.IMEIdonation}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }} />

                </>
            }
            {values.MceCheckDonation &&
                <>
                    <Grid size={{ xs: 12, sm: 3 }}>
                        <TextField
                            fullWidth
                            id="ReasonDonation"
                            select
                            required
                            label="Select Reason"
                            value={values.ReasonDonation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="ReasonDonation"
                            slotProps={{
                                inputLabel: {
                                    shrink: true, // Prevent the label from moving up
                                },
                            }}
                            error={touched.ReasonDonation && Boolean(errors.ReasonDonation)}
                            helperText={touched.ReasonDonation && errors.ReasonDonation}
                        >
                            {dropDowns.reasons.map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }} />
                </>
            }
            <Grid size={{ xs: 12, sm: 3 }}>
                <TextField
                    fullWidth
                    id="ManufacturerDonation"
                    select
                    required
                    label="Select Manufacturer"
                    value={values.ManufacturerDonation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="ManufacturerDonation"
                    slotProps={{
                        inputLabel: {
                            shrink: true, // Prevent the label from moving up
                        },
                    }}
                    error={touched.ManufacturerDonation && Boolean(errors.ManufacturerDonation)}
                    helperText={touched.ManufacturerDonation && errors.ManufacturerDonation}
                >
                    {dropDowns.manufacturer.map((item) => (
                        <MenuItem key={item} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }}>
                <TextField
                    fullWidth
                    id="ModelDonation"
                    name="ModelDonation"
                    label="Model"
                    value={values.ModelDonation}
                    slotProps={{
                        inputLabel: { shrink: true },
                        input: { inputProps: { maxLength: 15 } }
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ModelDonation && Boolean(errors.ModelDonation)}
                    helperText={touched.ModelDonation && errors.ModelDonation}
                />
            </Grid>
            {/* <Grid size={{ xs: 12, sm: 6 }} /> */}
            <Grid size={{ xs: 12, sm: 3 }}>
                <TextField
                    fullWidth
                    id="Seal1"
                    name="Seal1"
                    required
                    label="Seal 1"
                    value={values.Seal1}
                    slotProps={{
                        inputLabel: {
                            shrink: true, // Prevent the label from moving up
                        },
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Seal1 && Boolean(errors.Seal1)}
                    helperText={touched.Seal1 && errors.Seal1}
                />
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }}>
                <TextField
                    fullWidth
                    id="Seal2"
                    name="Seal2"
                    required
                    label="Seal 2"
                    value={values.Seal2}
                    slotProps={{
                        inputLabel: {
                            shrink: true, // Prevent the label from moving up
                        },
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Seal2 && Boolean(errors.Seal2)}
                    helperText={touched.Seal2 && errors.Seal2}
                />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
                <TextField
                    fullWidth
                    id="FreeTextDonation"
                    name="FreeTextDonation"
                    label="Notes"
                    multiline
                    rows={3}
                    value={values.FreeTextDonation}
                    slotProps={{
                        inputLabel: {
                            shrink: true, // Prevent the label from moving up
                        },
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                </Grid>
            <Grid size={{ xs: 12, sm: 12 }} />
            <Grid size={{ xs: 12, sm: 6 }}>
                <Typography>
                    <ul>
                        <li>
                            <b>Please make sure all sims and e-sim data is removed from the device where applicable.</b>
                        </li>
                        <li>
                            <b>Factory Reset.</b> All customer data on the device must be removed before the device is recycled. Please refer to the factory reset process in e-learning for any additional support required. Device help link - https://support.tescomobile.com/customer/picker.html
                        </li>
                    </ul>
                </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.FactoryReset}
                            onChange={(e) => setFieldValue('FactoryReset', e.target.checked)}
                            name="FactoryReset"
                            color="primary"
                        />
                    }
                    label="Tick if you are unable to perform factory reset"
                />
                {values.FactoryReset &&
                <Grid size={{ xs: 12, sm: 6 }}>
                    <TextField
                        fullWidth
                        id="NoneFactoryResetReason"
                        select
                        label="NoneFactoryResetReason"
                        value={values.NoneFactoryResetReason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="NoneFactoryResetReason"
                        variant='outlined'
                        required
                        slotProps={{
                            inputLabel: {
                                shrink: true, // Prevent the label from moving up
                            },
                        }}
                        error={touched.NoneFactoryResetReason && Boolean(errors.NoneFactoryResetReason)}
                        helperText={touched.NoneFactoryResetReason && errors.NoneFactoryResetReason}
                    >
                        {dropDowns.resetReason.map((item) => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </TextField>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

export default Recycle;
